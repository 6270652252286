.paddocktime__container {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 900;
  width: 280px;
}

.paddocktime__footerlegend--container {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 400;
}

.paddocktime__footerlegend--container > h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  text-transform: uppercase;
}

.paddocktime__footerlegend--content {
  display: flex;
}

.paddocktime__footerlegend--content > div:not(:first-child) {
  margin-left: 10px;
}
