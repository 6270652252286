.productsContainer {
  height: 100%;
}

.tableColumn {
  width: 100%;
  height: 62px;
  left: 0px;
  background: #4d4d4d;
  border: 2px solid #E5E5E5;
  display: flex;
  align-items: center;
  font-family: "Fira Sans", monospace;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0;
}

.tableColumn>div {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

.tableColumn>div:nth-child(1) {
  width: 400px;
}

.tableColumn>div:nth-child(2) {
  width: 400px;
}

.tableColumn>div:nth-child(3) {
  width: 400px;
}

.tableColumn>div:nth-child(4) {
  width: 100px;
}

.tableViewRows {
  border-radius: 0px 0px 10px 10px;
  border: 2px solid #f1f1f1;
  border-right: 0;
  overflow: hidden;
}