.cattleMap_background {
    height: 100%;
    position: absolute;
    z-index: 400;
    padding: 50px;
    width: calc(100vw - 340px);
    background: #f1f1f1ef;
}

@media print {
    .cattleMap_background {
        width: 100%
    }
}

.cattleMap_page {
    display: flex;
    flex-direction: column;
    gap: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
}

.cattleMap_page::-webkit-scrollbar {
    display: none;
}

.cattleMap_header {
    display: flex;
    gap: 20px;
    align-items: center;
}

.cattleMap_month {
    font-family: "Fira Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #324440;
}

.cattleMap_title {
    font-family: "Fira Sans";
    font-size: 30px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-align: left;
    color: #4d4d4d;
    text-transform: uppercase;
    margin: 0;
}

.cattleMap_subtitle {
    font-family: "Fira Sans";
    font-size: 55px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.02em;
    text-align: left;
    color: #4d4d4d;
    margin: 0;
}