.batchesspecsDetails__container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 0px 0px 10px 10px;
  align-items: center;
  padding: 5px 13px;
}

.batchesspecsDetails__container_sigla {
  display: flex;
  align-items: center;
}

h3.loteDetails {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #4d4d4d;
  margin: 0;
  padding: 0;
  margin-left: 8px;
}

h3.qtdDetails {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: #999999;
  text-align: center;
  width: 100%;
}

h3.capacity {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #999999;
  text-align: center;
  width: 100%;
}