.area__container {
  margin-left: 50px;
}

.area__content {
  display: flex;
}

.area__form {
  min-width: 448px;
  min-width: 448px;
}

.area__title--container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.area__title--container h2 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5625rem;
  letter-spacing: 0.02em;
  line-height: 30px;
  color: #4d4d4d;
  margin-top: 50px;
}

.area__title--container h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625rem;
  letter-spacing: 0.02em;
  color: #4d4d4d;
}

.area__title--container button {
  background: transparent;
  border: none;
  outline: none;
}

.area__textinput--container {
  margin-top: 20px;
}

.area__textinput--container label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.375rem;
  color: #4d4d4d;
  margin-bottom: 10px;
}

.area__textinput--container .area__button--readonly {
  background: transparent;
  outline: none;
  border: none;
  margin-top: 10px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: #62b8ae;
}

.area__dropdown--address {
  display: flex;
  justify-content: space-between;
}

.area__dropdown--address .area__textinput--container:first-child {
  width: 400px;
}

.area__dropdown--address .area__textinput--container:last-child {
  width: 90px;
}

.area__textinput--container .noContent--msg {
  display: block;
  text-align: left;
  width: 100%;
  font-family: "Fira Sans";
}

.area__divider {
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin: 20px 0;
}

.area__button--delete {
  margin-bottom: 10px;
}

.area__dropdown--address .area__textinput--container:last-child .react-select div[class$="-option"] {
  padding-left: 0 !important;
}

.area__dropdown--address .area__textinput--container:last-child .react-select div[class$="-control"] {
  padding-left: 10px !important;
}

.area__textinput--container .slider-seasons {
  margin: 40px 0;
  margin-bottom: 100px;
}

.area__drawarea--container .leaflet-control {
  visibility: hidden;
}

.area__drawarea--container {
  margin-left: 70px;
  transform: translateY(-40px);
}

.area__drawarea--container > div {
  padding: 20px;
  width: 400px;
  height: 300px;
  background: url("../../../../../assets/png/genericMap.png");
  display: flex;
  align-items: flex-end;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.area__drawarea--container > span {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  color: #ff6161;
  display: block;
  margin-top: 10px;
}

.buttondraw__container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttondraw__container > svg {
  width: 18px;
  resize: both;
  margin-right: 16px;
}

.buttondraw__container > svg > path {
  fill: #ffffff;
}

.buttondraw__container > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 !important;
}

.hasPolygon > div {
  padding: 0;
}

.hasPolygon .gmnoprint {
  display: none;
}

.hasPolygon .buttonwithchildren__container {
  width: 52px;
  height: 52px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

.hasPolygon .buttonwithchildren__container svg {
  margin: 0;
}

.drawpolygon__header {
  position: absolute;
  z-index: 99;
  top: 40px;
  right: 40px;
}

.drawpolygon__header > .buttonwithchildren__container {
  width: 52px;
  height: 52px;
}

.drawpolygon__header > .buttonwithchildren__container svg {
  width: 20px;
  height: 20px;
}

.drawfarm__label--container .infoBox {
  width: 161px;
}

.labelarea__container,
.labelarea__container > .drawfarm__label--container {
  width: auto;
}

.area__drawarea--container .leaflet-container {
  position: absolute !important;
}

.dividercontainer .area__divider {
  max-width: 450px !important;
  margin-left: 50px;
}

.area__textinput--initials {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  border: solid 1px var(--gray-40);
  border-radius: 9px;
}

.initials__read {
  background: #f1f1f1;
}

.initials__read > label {
  color: #999 !important;
}

.area__textinput--initials > label {
  margin: 0;
  padding: 0;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #4d4d4d;
}

.area__textinput--initials .textInput__container,
.area__textinput--initials .textInput__container > input {
  width: 100%;
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}

.area__textinput--container > small {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ff6161;
  display: block;
  margin-top: 10px !important;
}

.area__textinput--container .isInvalid {
  border: solid red 1px !important;
}
