.capacitybvroute__barnumber--container {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 400;
}

.capacitybvroute__barnumber--title {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  text-transform: uppercase;
}

.capacitybvroute__barnumber--bar {
  margin-top: 10px;
  width: 300px;
  height: 20px;
  border: 2px solid #ffffff;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  background: linear-gradient(
    90deg,
    #ffffff 0%,
    #ffffff 20%,
    #ffca80 20%,
    #ffca80 40%,
    #ff9501 40%,
    #ff9501 60%,
    #ff4b00 60%,
    #ff4b00 80%,
    #ff0000 80%,
    #ff0000 100%
  );
  margin-right: -1px;
}

.capacitybvroute__barnumber--containerlegend {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.capacitybvroute__barnumber--containerlegend > span {
  width: 50%;
  font-family: "Fira Sans", monospace;
  font-weight: 500;
  font-size: 0.8rem;
  color: #ffffff;
  text-align: right;
  margin-right: -14px;
  margin-top: 10px;
}

.capacitybvroute__barnumber--containerlegend > span::after {
  position: absolute;
  content: "|";
  font-weight: 700;
  margin-top: -25px;
  margin-left: -16px;
}

.capacitybvroute__labelMap__container {
  background: #fff;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}

.capacitybvroute__labelMap__container > strong {
  font-family: "Fira Code", monospace;
  font-weight: 500;
  font-size: 0.85rem;
  color: #324440;
}

.capacitybvroute__labelMap__container > span {
  display: block;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  border-radius: 50%;
}

@media (max-width: 480px) {
  .capacitybvroute__barnumber--container {
    left: 20px;
  }
}
