.pastureoccupation {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 999;
  width: 280px;
}

.pastureoccupation__legend--container {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 400;
}

.occupation__legend--title {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  text-transform: uppercase;
}

.occupation__legend--container {
  display: flex;
  justify-content: space-between;
}

.occupation__legend--container > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.occupation__legend--container > div > .legendDescription {
  margin-left: 5px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 14px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  width: 110px;
}

.occupation__legend--container > div > .last {
  width: 200px;
}

.elipse-icon {
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
}