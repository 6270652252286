.lot_container {
  width: 500px;
}

.lot__content {
  width: 500px;
}

.lot__content {
  min-width: 500px;
}

.lot__form {
  min-width: 500px;
}

.lot__dropdown--farm {
  display: flex;
  justify-content: space-between;
}

.lot__dropdown--retreat {
  display: flex;
  justify-content: space-between;
}

.lot__textinput--container {
  margin-top: 20px;
}

.lot__textinput--container {
  margin-top: 20px;
}

.lot__textinput--container label {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.375rem;
  color: #4d4d4d;
  margin-bottom: 10px;
}

.lot__textinput--container .lot__button--readonly {
  background: transparent;
  outline: none;
  border: none;
  margin-top: 10px;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  color: #62b8ae;
}

.lot__textinput--container .lot__ua--readonly {
  border-radius: 37px;
}

.lot__divider {
  width: 100%;
  height: 2px;
  background: #f1f1f1;
  margin: 20px 0;
}

.lot__title--container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.lot__title--container h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5625rem;
  letter-spacing: 0.02em;
  color: #4d4d4d;
}

.lot__title--container button {
  background: transparent;
  border: none;
  outline: none;
}

.lot__button--delete {
  margin-bottom: 10px;
  margin-top: 20px;
}

.lot__title--category {
  margin-top: 25px;
  color: #4d4d4d;
}

.lot__subtitle--category {
  color: #999999;
}

.lot__select--category input {
  margin-right: 10px;
}

.lot__select--category span {
  margin-left: 25px;
  font-size: medium;
}

.textinput__initials--container {
  display: flex;
  align-items: center;
  justify-items: flex-start;
  background: #f1f1f1;
  border-radius: 10px;
  padding: 0 20px;
  border: solid 1px transparent;
}

.textinput__initials--container .textInput__container > input,
.textinput__initials--container > label {
  margin: 0;
  padding: 0;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 24px;
  color: #4d4d4d;
  text-transform: uppercase;
}

.textinput__initials--container .textInput__container > input,
.lot__textinput--isEditable .textInput__container > input {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  text-transform: uppercase;
}

.lot__textinput--isEditable {
  background: #ffffff;
  border: solid 1px var(--gray-40);
}

.lot__textinput--isEditable > label {
  color: #bcbbc4;
}

.lot__textinput--isEditable > label:hover {
  cursor: text;
}

.lot__textinput--isEditable .textInput__container > input:focus {
  border: none;
}

.lot__textinput--container .react-select div[class$="-multiValue"] {
  background-color: #f1f1f1;
}

.lot__textinput--container .react-select div[class$="-multiValue"] {
  background-color: #f1f1f1;
}

.lot__textinput--container
  .react-select
  div[class$="-multiValue"]
  div:last-child {
  padding: 0;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 22px;
  color: #4d4d4d;
}

.lot__textinput--container
  .react-select
  div[class$="-multiValue"]
  div:last-child:hover {
  background-color: #62b8ae;
}

.lot__textinput--container
  .react-select
  div[class$="-control"]
  > div
  div[class$="-multiValue"]:not(:last-child)::after {
  line-height: 22px;
  margin-right: 5px;
}

.lot__textinput--container .errorInitials {
  display: block;
  width: 100%;
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ff6161;
  margin-top: 10px;
}

.batches_container {
  margin-left: 50px;
}

.batch_column {
  width: 550px;
}

.batch_column_mapa {
  height: 20px;
  width: 550px;
  margin-left: 50px;
  top: 50px;
  position: sticky;
}

.container___buttonaddBatch {
  margin-left: 1.1rem;
  margin-bottom: 2rem;
  padding-top: 1.5rem;
}

.area__batchmap--container .leaflet-control {
  visibility: hidden;
}

.area__batchmap--container {
  margin-left: 70px;
  transform: translateY(-40px);
}

.area__batchmap--container > div {
  padding: 20px;
  width: 600px;
  height: 500px;
  display: flex;
  align-items: flex-end;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.area__batchmap--container > span {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  color: #ff6161;
  display: block;
  margin-top: 10px;
}

.FlytoBounds_container {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.subtitle__grass {
  background: #4d4d4d;
  opacity: 0.9;
  height: 30px;
  z-index: 100000;
  position: relative;
  border: 1px solid #999999;
  border-radius: 10px;
  height: 50px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 20px;
  width: fit-content;
}

.subtitle__grass > span {
  color: #ffffff;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.subtitle__grass > svg {
  width: 30px;
}

.subtitle__animals {
  background: white;
  opacity: 0.9;
  z-index: 1000;
  position: relative;
  border: 1px solid #999999;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 50px;
  padding: 20px;
  width: fit-content;
  margin-left: auto;
}

.subtitle__animals > span {
  color: #999999;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.subtitle__animals > svg {
  width: 30px;
}

.cardBatch {
  width: auto;
  height: 60px;
  border: solid 2px #bcbbc4;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #4d4d4d;
}

.cardBatch:hover {
  color: #4d4d4d;
}

.cardBatch .initials {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.cardBatch .info {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #999999;
}

.grid_batches {
  display: grid;
  grid-template-columns: repeat(3, minmax(205px, 1fr));
}
