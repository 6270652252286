.batchesAvailableDetails__container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 0px 0px 10px 10px;
  align-items: center;
  padding: 5px 13px;
}

.batchesAvailableDetails__container > h3 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  margin-top: 10px;
  letter-spacing: 0.05em;
  color: #999999;
  text-align: center;
  width: 100%;
}
