.customToolTip__container {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}

.customToolTip__container::before {
  display: none;
}
