.microareaSpecs__container {
  width: 100%;
}

.microareaSpecs__microarea {
  width: 100%;
  height: 50px;
  background: #4d4d4d;
  border: 1px solid #999999;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 20px 13px;
}

.havebatch > .microareaSpecs__microarea {
  border-radius: 10px 10px 0px 0px !important;
}

.microareaSpecs__microarea > svg {
  width: 24px;
  resize: both;
}

.microareaSpecs__microarea > svg > path {
  fill: #57ce71;
}

.microareaSpecs__microarea > h3 {
  margin: 0;
  padding: 0;
  margin-left: 8px;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ffffff;
}
