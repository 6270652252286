.grazingfilter__container {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 999;
  width: 280px;
}

.footerlegend__container {
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 400;
}

.footerlegend__content {
  display: flex;
}

.footerlegend__content > div {
  margin-right: 5px;
}

.footerlegend__container > h6 {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  text-transform: uppercase;
}
.footerlegend__container article {
  display: flex;
  align-items: center;
}
.footerlegend__container article strong {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 14px;
  letter-spacing: 0.03em;
  font-variant: small-caps;
  color: #ffffff;
  text-transform: uppercase;
}

.labelbatches__container {
  width: 52px;
  height: 52px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
