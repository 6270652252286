.columnPastureManagement {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.body__formPastureManagement {
    overflow-y: scroll;
    height: calc(100vh - 330px);
    position: relative;
    display: flex;
    gap: 60px;
    margin-bottom: 30px;
}